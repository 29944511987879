import axios from '@axios'
export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchCats(ctx, queryParams) {
            var data = JSON.stringify(queryParams);
            
            return new Promise((resolve, reject) => {
              axios
                .post('/blogs/v1/category/getall',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        
        addCategory(ctx,queryParams){
            var data = JSON.stringify(queryParams);
            
            return new Promise((resolve, reject) => {
              axios
                .post('/blogs/v1/category/add',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })

        },
        updateCategory(ctx,queryParams){
            var data = JSON.stringify(queryParams);
            
            return new Promise((resolve, reject) => {
              axios
                .put('/blogs/v1/category/update/'+queryParams.id,data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })

        },
        deleteCategory(ctx,id){
          
            
            return new Promise((resolve, reject) => {
              axios
                .delete('/blogs/v1/category/delete/'+id)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })

        },
        fetchBlogs(ctx,queryParams){
          var data = JSON.stringify(queryParams);
            
          return new Promise((resolve, reject) => {
            axios
              .post('/blogs/v1/getall',data)
              .then(response => resolve(response))
              .catch(error => reject(error))
          })
        },
        addBlog(ctx,queryParams){
            //var data = JSON.stringify(queryParams);
            
            return new Promise((resolve, reject) => {
              axios
                .post('/blogs/v1/add',queryParams.formData)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })

        },
        updateBlog(ctx,queryParams){
         
           // var data = JSON.stringify(queryParams.formData);
            
            return new Promise((resolve, reject) => {
              axios
                .put('/blogs/v1/update/'+queryParams.data.id,queryParams.formData,{
                  headers:{
                    'Content-Type': 'multipart/form-data',
                    'dataType':'json'
                  }
                })
                .then(response => resolve(response))
                .catch(error => reject(error))
            })

        },
        deleteBlog(ctx,id){
          
            
          return new Promise((resolve, reject) => {
            axios
              .delete('/blogs/v1/delete/'+id)
              .then(response => resolve(response))
              .catch(error => reject(error))
          })

      }
       
    }
}